import { arrayOf, shape, string } from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { HEADING_MAPPING } from '../../components/headings/mapping';
import { Sitemeta } from '../../components/core/sitemeta';
import { entryType, responseType } from '../../types';

export const query = graphql`
  query News(
    $newsId: [CraftGQL_QueryArgument]!
  ) {
    response: craftgql {
      page: entry(id: $newsId) {
        title
        
        ...NewsContentsFragment

        ...on CraftGQL_news_news_Entry {
          headings: pageHeadings {
            typename: __typename
            ... on CraftGQL_pageHeadings_minimalHeading_BlockType {
              headline
            }
          }
          departments: pageDepartments {
            ... on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }

          metadata: pageMetadata {
            ...MetadataFragment
          }
        }
      }
    }
  }
`;

const News = ({ data }) => {
  const {
    title,
    contents,
    departments: [department],
    headings,
    metadata: [metadata],
  } = data.response.page;

  // const Header = HEADING_MAPPING['CraftGQL_pageHeadings_minimalHeading_BlockType'];

  return (
    <>
      <Sitemeta
        title={title}
        {...metadata || null}
        color={department?.color}
      />
      {/* <Header headline={title} /> */}
      <Renderer contents={headings} mapping={HEADING_MAPPING} />
      <Renderer contents={contents} mapping={CONTENT_MAPPING} />
    </>
  );
};

News.propTypes = {
  ...responseType({
    page: entryType({
      title: string.isRequired,
      contents: arrayOf(shape({
        typename: string,
      })).isRequired,
      departments: arrayOf(shape({
        color: string.isRequired,
      })).isRequired,
      headings: arrayOf(shape({
        typename: string,
      })).isRequired,
    }).isRequired,
  }),
};

export default News;
